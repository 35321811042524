export const DIALOG_TYPES = {
  PRIMARY: 'primary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
};

export const CARD_ACTIONS = {
  REDIRECT: 'REDIRECT',
  MONTH_STEP_RANGE: 'MONTH_STEP_RANGE',
  TWO_SELECT_LIST_INPUTS: 'TWO_SELECT_LIST_INPUTS',
  DATEPICKER: 'DATEPICKER',
};
