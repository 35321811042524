<template>
  <el-form-item :label="$t(label)">
    <el-input v-model="model" />
  </el-form-item>
</template>

<script>
import { usePropModel } from '@/modules/core/compositions/prop-model';

export default {
  name: 'FormInput',
  props: {
    label: String,
    value: [String, Number],
  },
  setup() {
    return {
      model: usePropModel(),
    };
  },
};
</script>

<style scoped></style>
