import { ref } from 'vue';
import { useNotification } from '@/modules/core';

/**
 * wrap your submit function with composition, error / success handling messages, submit flag, etc.
 * @param {function} submitFn
 * @param {{[success]: {title: string, [message]: string}, [error]: {title: string, [message]: string}}} [messages]
 * @returns {{submitting: ref<boolean>, submit: function<Promise<void>>}}
 */
export function useSubmitting(submitFn, messages = {}) {
  const submitting = ref(false);
  const { error, success } = useNotification();

  return {
    submitting,
    submit: async (...args) => {
      submitting.value = true;
      try {
        await submitFn(...args);
        if (messages.success) {
          success(messages.success.title, messages.success.message);
        }
      } catch (err) {
        const errorMessage = messages.error || err || {};
        console.error('useSubmitting', err);
        error(errorMessage.title, errorMessage.message);
      } finally {
        submitting.value = false;
      }
    },
  };
}
