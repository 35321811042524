import { reactive } from 'vue';

export function useEditModal() {
  const editModal = reactive({
    open: false,
    item: null,
  });

  return {
    openEditModal: (row) => {
      editModal.open = true;
      editModal.item = row || null;
    },
    closeEditModal: () => {
      editModal.open = false;
      editModal.item = null;
    },
    editModal,
  };
}
