import { gql } from '@apollo/client/core';

export const NOTIFICATIONS_QUERY = gql`
  query retrieveNotifications($businessId: ID!) {
    notifications(businessId: $businessId) {
      id
      title
      description
      businessId
      createdAt
      read
      seen
      domain
      data
    }
  }
`;

export const UNREAD_NOTIFICATION_COUNT_BY_BUSINESS_QUERY = gql`
  query notificationCountByBusiness {
    notificationCountByBusiness {
      businessId
      unreadNotificationCount
      unseenNotificationCount
    }
  }
`;

export const NOTIFICATION_MARK_AS_READ_MUTATION = gql`
  mutation notificationMarkAsRead($notificationId: ID!) {
    notificationMarkAsRead(notificationId: $notificationId) {
      id
      read
    }
  }
`;

export const NOTIFICATION_MARK_AS_SEEN_MUTATION = gql`
  mutation notificationMarkAsSeen($date: Date!, $businessId: ID) {
    notificationMarkAsSeen(date: $date, businessId: $businessId)
  }
`;
