<template>
  <el-date-picker v-model="dateValue" value-format="yyyy-MM-dd" v-bind="$attrs" v-on="filteredListeners" />
</template>

<script type="text/javascript">
import moment from 'moment';

export default {
  props: ['value'],
  computed: {
    dateValue: {
      get() {
        return this.value && moment.utc(this.value).format('YYYY-MM-DD');
      },
      set(value) {
        this.$emit('input', value && moment.utc(value, 'YYYY-MM-DD').toDate());
      },
    },
    filteredListeners() {
      const filteredListeners = {};
      Object.keys(this.$listeners)
        .filter((key) => key !== 'input')
        .forEach((key) => (filteredListeners[key] = this.$listeners[key]));
      return filteredListeners;
    },
  },
};
</script>
