import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

export const useAllTenants = () => {
  const { result, loading, refetch } = useQuery(MEMBERSHIPS);
  const tenants = useResult(result, [], ({ me }) => me.memberships.map(({ business }) => business));

  return {
    tenants,
    loading,
    refetch,
  };
};

const MEMBERSHIPS = gql`
  query tenants {
    me {
      id
      memberships {
        role
        business {
          id
          type
          name
        }
      }
    }
  }
`;
