import { ref, watch } from 'vue';

export const useLoading = (queryLoading, variables, options = { value: { enabled: true } }) => {
  const loading = ref(true);
  watch(
    queryLoading,
    (newLoading) => {
      if (!newLoading) loading.value = newLoading;
    },
    { immediate: true }
  );
  watch(
    () => variables,
    () => (loading.value = options.value.enabled),
    { deep: true }
  );
  return loading;
};
