<template>
  <div class="table-wrapper">
    <table class="styled-table">
      <thead>
        <tr>
          <slot name="header" />
        </tr>
      </thead>
      <tbody>
        <slot name="body" />
      </tbody>
      <tfoot v-if="$slots.footer">
        <tr>
          <slot name="footer" />
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Table',
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
@import '@/stylesheets/deprecated/typography';

.table-wrapper {
  position: relative;
  flex: 1;
  background-color: #fff;
  overflow: auto;
}

.styled-table {
  width: 100%;
  color: $typography-primary;
  border-collapse: separate;
  border-spacing: 0;
}

tr {
  height: 68px;
}

th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  color: #828a94;
  font-weight: normal;
  font-size: $font-smaller;
}

th,
td {
  padding-left: 30px;
  border-bottom: 1px solid $outline;

  &:last-child {
    padding-right: 30px;
  }

  @at-root [dir='rtl'] #{&} {
    &:first-child {
      padding-right: 30px;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

tbody {
  font-size: 14px;

  tr:last-child td {
    border-bottom: none;
  }
}

tfoot td {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid $outline;
  border-bottom: none;
}
</style>
