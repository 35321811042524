import { useQuery, useResult } from '@vue/apollo-composable';
import { NOTIFICATIONS_QUERY } from './graphql';

import { useNotification } from '@/modules/core';

export function useNotifications(locale, businessId) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(NOTIFICATIONS_QUERY, { businessId }, () => ({
    enabled: !!businessId.value,
    context: {
      headers: {
        'Accept-Language': locale.value,
      },
    },
  }));

  const notifications = useResult(result, []);

  onError((param) => {
    error();
    console.error('useNotification', param);
  });

  return {
    notifications,
    loading,
    refetch,
  };
}
