import { useQuery, useResult } from '@vue/apollo-composable';
import { UNREAD_NOTIFICATION_COUNT_BY_BUSINESS_QUERY } from './graphql';

import { useNotification } from '@/modules/core';

export function useUnreadNotificationCountByBusiness() {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(UNREAD_NOTIFICATION_COUNT_BY_BUSINESS_QUERY);

  const unreadNotificationCountByBusiness = useResult(result, []);

  onError((param) => {
    error();
    console.error('useUnreadNotificationCountByBusiness', param);
  });

  return {
    unreadNotificationCountByBusiness,
    loading,
    refetch,
  };
}
