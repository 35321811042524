import { isNil } from 'ramda';
import i18n from '@/imports/startup/client/i18n';
import { useTenancy } from '@/modules/auth';

const COUNTRY_CURRENCY = {
  IL: 'ILS',
  US: 'USD',
};

const defaultOptions = {};

export const useCurrency = (options = defaultOptions) => {
  const { currentTenant } = useTenancy();
  const countryCode = currentTenant.value.countryCode ?? COUNTRY_CURRENCY.IL;

  const currencyBaseConfig = {
    style: 'currency',
    currency: COUNTRY_CURRENCY[countryCode],
  };
  const validateNumber = (value) => !isNil(value) && typeof value === 'number' && !Number.isNaN(value);

  const formatNumber = (value, optionsOverride) =>
    Number(value).toLocaleString(i18n.locale, {
      ...currencyBaseConfig,
      ...options,
      ...optionsOverride,
    });

  const formatToCurrency = (value, optionsOverride = {}) =>
    validateNumber(value) ? formatNumber(value, optionsOverride) : null;

  const formatCentsToCurrency = (value, optionsOverride = {}) =>
    validateNumber(value) ? formatNumber(value / 100, optionsOverride) : null;

  return { formatToCurrency, formatCentsToCurrency };
};
