import { getCurrentInstance } from 'vue';
import { openProductModal } from '@/modules/products/store/product-modal';

export function useSelectedItem() {
  const { $router } = getCurrentInstance().proxy;

  return {
    followLink({ item }) {
      if (item.obj === 'item') {
        openProductModal(item.location.params.itemId);
      } else {
        $router.push(item.location);
      }
    },
  };
}
