import { computed, reactive } from 'vue';
import { getCurrentInstance } from 'vue';

export function useClonedModel(prop, fallbackModel) {
  return reactive(prop ? JSON.parse(JSON.stringify({ ...fallbackModel, ...prop })) : fallbackModel);
}

export function usePropModel(key = 'value') {
  const instance = getCurrentInstance().proxy;
  const props = instance.$props;
  const emit = instance.$emit.bind(instance);

  return computed({
    get: () => props[key],
    set: (value) => emit('input', value),
  });
}
