import XLSX from 'xlsx';

export function readXlsxFile(file) {
  const reader = new window.FileReader();
  return new Promise((resolve) => {
    reader.onload = function (e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const entries = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

      resolve(entries);
    };
    reader.readAsBinaryString(file);
  });
}
