import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

export const useTenants = (variables = {}) => {
  const { result, loading, refetch, onResult } = useQuery(TENANTS, variables, () => {
    return {
      fetchPolicy: 'cache-first',
      debounce: 500,
    };
  });
  const tenants = computed(() => (result.value ? result.value.tenantSelection?.nodes ?? [] : []));

  return {
    tenants,
    loading,
    refetch,
    onResult,
  };
};

const TENANTS = gql`
  query tenants($search: String) {
    tenantSelection(search: $search) {
      nodes {
        id
        name
        countryCode
        legalName
        tenant
        number
        type
        logoUrl
        capabilities {
          restaurant
          serviceProvider
          supplier
          bookkeeping
        }
        contact {
          name
          phone
          address
          email
        }
      }
    }
  }
`;
