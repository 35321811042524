import { getCurrentInstance } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';

export const useNotification = () => {
  const $t = useTranslate();
  const { $notify } = getCurrentInstance().proxy;

  return {
    error: (title = 'errors.oopsTitle', message = 'errors.action') => {
      $notify.error({ title: $t(title), message: $t(message) });
    },
    success: (title, message) => {
      $notify.success({ title: $t(title), message: $t(message) });
    },
  };
};
