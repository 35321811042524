<template>
  <label class="file-upload">
    <i class="el-icon-upload" />
    <input type="file" @change="emitUpload" />
  </label>
</template>

<script>
export default {
  name: 'UploadFileButton',
  setup(_, { emit }) {
    return {
      emitUpload(event) {
        if (event.target.files) {
          emit('upload', event.target.files[0]);
          event.target.value = '';
        }
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.file-upload {
  margin: 0 10px;
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid $outline;

  &:hover {
    background-color: $lighter-gray;
    color: $primary;
  }

  input {
    display: none;
  }
}
</style>
